import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "PAGES"
  },
  {
    id: "profile",
    title: "Profile",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/profile",
    collapsed: false
  },
  {
    id: "artwork",
    title: "Artwork",
    type: "item",
    icon: <Icon.Chrome size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/artwork",
    collapsed: false
  },
  {
    id: "exhibition",
    title: "Exhibitions",
    type: "item",
    icon: <Icon.Image size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/exhibitions",
    collapsed: false
  },
  {
    id: "exhibitors",
    title: "Exhibitors",
    type: "item",
    icon: <Icon.Users size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/exhibitors",
    collapsed: false
  },
  {
    id: "my_request",
    title: "Artwork Request",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/artwork-requests",
    collapsed: false
  },
  {
    id: "puchase_request",
    title: "Purchase Request",
    type: "item",
    icon: <Icon.List size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/purchase-request",
    collapsed: false
  },
  {
    id: "accountSettings",
    title: "Account Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/account-settings"
  },
  {
    id: "complaints",
    title: "Contact Us",
    type: "item",
    icon: <Icon.Mail size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/contact-us"
  },
  // {
  //   id: "todo",
  //   title: "Todo",
  //   type: "item",
  //   icon: <Icon.CheckSquare size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/todo/:filter",
  //   filterBase: "/todo/all"
  // },
  // {
  //   id: "calendar",
  //   title: "Calendar",
  //   type: "item",
  //   icon: <Icon.Calendar size={20} />,
  //   permissions: ["admin", "editor"],
  //   navLink: "/calendar"
  // },
  
]

export default navigationConfig
