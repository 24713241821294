import React from "react"
import { Card, CardHeader, CardTitle, CardBody, Button, Media } from "reactstrap"
import { MoreHorizontal, Facebook, Instagram, Twitter } from "react-feather"
import img from "../../../assets/img/portrait/small/avatar-s-11.jpg"
class AboutCard extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader>
        <Media className="mr-1" left href="#">
            <Media
              className="rounded-circle"
              object
              src={img}
              alt="User"
              height="64"
              width="64"
            />
          </Media>
          
         
        </CardHeader>
        <CardBody>
        <CardTitle className="mt-25">About</CardTitle>
          
          
          <div className="mt-1">
            <h6 className="mb-0">Email:</h6>
            <p>bucketful@fiendhead.org</p>
          </div>
          <div className="mt-1">
            <h6 className="mb-0">Website:</h6>
            <p>www.pixinvent.com</p>
          </div>
          <div className="mt-1">
            <Button color="primary" size="sm" className="btn-icon mr-25 p-25">
              <Facebook />
            </Button>
            <Button color="primary" size="sm" className="btn-icon mr-25 p-25">
              <Twitter />
            </Button>
            <Button color="primary" size="sm" className="btn-icon p-25">
              <Instagram />
            </Button>
          </div>
        </CardBody>
      </Card>
    )
  }
}
export default AboutCard
